<template>
	<div class="Department">
		<w-navTab titleText="爱心排行"></w-navTab>
		<loading-page :loading="loadingPage"></loading-page>
		<div class="container">
			<div class="head-nav">
				<div :class="[{ color: active == 1 }]" @click="onChange(1)">当日排名</div>
				<div :class="[{ color: active == 2 }]" @click="onChange(2)">当月排名</div>
				<div :class="[{ color: active == 3 }]" @click="onChange(3)">年度排名</div>
				<div :class="[{ color: active == 4 }]" @click="onChange(4)">去年排名</div>
			</div>

			<div class="head">
				<div class="head-item m-left" v-for="(item, key) in list" v-if="key == 1">
					<div class="head-item-picture item-picture"><img src="@/assets/img/icon208.png" alt="" /></div>
					<div class="picture-middle-two"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.district_name }}</div>
					<div class="text">人均{{ item.avg }}</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon221.png" alt="" /></div>
						<div class="text">{{ item.love_value ? item.love_value : 0 }}</div>
					</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon220.png" alt="" /></div>
						<div class="text">{{ item.user_num ? item.user_num : 0 }}</div>
					</div>
				</div>
				<div class="head-item" v-for="(item, key) in list" v-if="key == 0">
					<div class="head-item-picture"><img src="@/assets/img/icon207.png" alt="" /></div>
					<div class="picture-middle-one"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.district_name }}</div>
					<div class="text">人均{{ item.avg }}</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon221.png" alt="" /></div>
						<div class="text">{{ item.love_value ? item.love_value : 0 }}</div>
					</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon220.png" alt="" /></div>
						<div class="text">{{ item.user_num ? item.user_num : 0 }}</div>
					</div>
				</div>
				<div class="head-item m-right" v-for="(item, key) in list" v-if="key == 2">
					<div class="head-item-picture item-picture"><img src="@/assets/img/icon209.png" alt="" /></div>
					<div class="picture-middle-two"><img :src="item.logo" alt="" /></div>
					<div class="head-item-title">{{ item.district_name }}</div>
					<div class="text">人均{{ item.avg }}</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon221.png" alt="" /></div>
						<div class="text">{{ item.love_value ? item.love_value : 0 }}</div>
					</div>
					<div class="head-item-text">
						<div class="picture"><img src="@/assets/img/icon220.png" alt="" /></div>
						<div class="text">{{ item.user_num ? item.user_num : 0 }}</div>
					</div>
				</div>
			</div>

			<div class="middle">
				<div class="middle-item" v-for="(item, key) in list" v-if="key > 2">
					<div class="item-top">
						<div class="item-top-left">
							<div class="item-top-left-picture"><img :src="item.logo" alt="" /></div>
							<div class="item-top-left-title">{{ item.district_name }}</div>
						</div>
						<div class="item-top-right">人均{{ item.avg }}</div>
					</div>
					<div class="item-bottom">
						<div class="item-bottom-title">{{ key > 8 ? '' : '0' }}{{ key + 1 }}</div>
						<div class="item-bottom-text">
							<div class="picture"><img src="@/assets/img/icon220.png" alt="" /></div>
							<div class="text">人数：{{ item.user_num ? item.user_num : 0 }}</div>
						</div>
						<div class="item-bottom-text">
							<div class="picture"><img src="@/assets/img/icon221.png" alt="" /></div>
							<div class="text">爱心值：{{ item.love_value ? item.love_value : 0 }}</div>
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</template>

<script>
	import Rank from '@/api/rank.js';
	import LoadingPage from '@/components/LoadingPage';
	export default {
		name: 'Department',
		data() {
			return {
				active: 1,
				list: '',
				loadingPage: true,
			};
		},
		created() {
			this.onRank();
		},
		methods: {
			onRank() {
				let params = {
					order: this.active
				};
				Rank.district(params).then(res => {
					this.list = res.data;
					this.loadingPage = false;
				});
			},
			onChange(active) {
				this.active = active;
				this.onRank();
			}
		},
		components: {
			LoadingPage
		}
	};
</script>

<style scoped lang="less">
	.Department {
		.container {
			padding: 0 10px 10px 10px;
			box-sizing: border-box;
			.picture {
				width: 18px;
				min-width: 18px;
				height: 18px;

				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}

			.text {
				font-size: 14px;
				line-height: 20px;
				color: #666;
			}

			.head-nav {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 18px 10px 0 10px;
				border-bottom: 1px solid #ddd;

				div {
					font-size: 16px;
					line-height: 23px;
					color: #000;
					padding-bottom: 12px;
					margin-bottom: -1px;
				}
			}

			.color {
				font-weight: bold;
				color: #3377ff !important;
				border-bottom: 2px solid #3377ff;
			}

			.head {
				display: flex;
				justify-content: space-between;
				padding: 10px 0;

				.head-item {
					padding: 9px;
					background-color: #fff;
					text-align: center;
					border-radius: 20px 20px 0px 0px;
					position: relative;

					.head-item-picture {
						position: relative;
						z-index: 10;
						width: 115px;
						min-width: 115px;
						height: 115px;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.picture-middle-one {
						position: absolute;
						z-index: 5;
						top: 20px;
						left: 0;
						width: 100px;
						min-width: 100px;
						height: 100px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.picture-middle-two {
						position: absolute;
						z-index: 5;
						top: 17px;
						left: 0;
						width: 74px;
						min-width: 74px;
						height: 74px;
						margin-left: 50%;
						transform: translateX(-50%);

						img {
							width: 100%;
							height: 100%;
							border-radius: 50%;
							object-fit: cover;
						}
					}

					.item-picture {
						width: 85px;
						min-width: 85px;
						height: 85px;
					}

					.head-item-title {
						font-weight: bold;
						font-size: 18px;
						line-height: 26px;
						color: #333;
						margin-top: 5px;
					}

					.head-item-text {
						display: flex;
						align-items: center;
						justify-content: center;

						.text {
							margin-left: 3px;
						}
					}
				}

				.m-left {
					margin-top: 50px;
					border-radius: 15px 15px 0px 20px;
				}

				.m-right {
					margin-top: 50px;
					border-radius: 15px 15px 20px 0;
				}
			}

			.middle {
				.middle-item {
					margin-top: 10px;
					padding: 15px 10px;
					border-radius: 6px;
					background-color: #fff;

					.item-top {
						display: flex;
						justify-content: space-between;
						align-items: center;
						padding-bottom: 10px;
						border-bottom: 1px solid #f2f2f2;

						.item-top-left {
							display: flex;
							justify-content: flex-start;
							align-items: center;

							.item-top-left-picture {
								width: 32px;
								min-width: 32px;
								height: 32px;
								margin-right: 5px;

								img {
									width: 100%;
									height: 100%;
									border-radius: 50%;
									object-fit: cover;
								}
							}

							.item-top-left-title {
								width: 170px;
								font-weight: bold;
								font-size: 16px;
								line-height: 23px;
								color: #333;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}

						.item-top-right {
							font-weight: bold;
							font-size: 16px;
							line-height: 23px;
							color: #ff6969;
						}
					}

					.item-bottom {
						margin-top: 10px;
						display: flex;
						justify-content: space-between;
						align-items: center;

						.item-bottom-title {
							font-weight: bold;
							font-size: 18px;
							line-height: 20px;
							color: #3377ff;
						}

						.item-bottom-text {
							display: flex;
							justify-content: flex-start;
							align-items: center;
						}
					}
				}
			}
		}
	}
</style>
